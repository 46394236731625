import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IMigrationStatusResponse } from 'src/apis/types/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';
import { MIGRATION_STATUS_KEY, REFETCH_INTERVAL } from '../constants';

const MIGRATION_STATUS_URL_PATH = '/api/v2/resource-planner/migration-status';

const resourcePlannerFetch = (): Promise<IMigrationStatusResponse> =>
  getFetch({ path: MIGRATION_STATUS_URL_PATH, key: MIGRATION_STATUS_KEY });

export const useGetMigrationStatus = (refetch?: boolean) => {
  const [fetchCount, setFetchCount] = useState(0);
  const { data, ...restProps } = useQuery({
    queryFn: resourcePlannerFetch,
    queryKey: [MIGRATION_STATUS_KEY],
    refetchInterval: refetch ? REFETCH_INTERVAL : undefined,
    onSuccess: () => setFetchCount((prev) => prev + 1),
  });

  return {
    tenantId: data?.model?.properties.tenantId ?? '',
    isDataSynchronizationEnabled: data?.model?.properties.isDataSynchronizationEnabled ?? false,
    isResourceMigrated: data?.model?.properties.isResourceMigrated ?? false,
    isProjectMigrated: data?.model?.properties.isProjectMigrated ?? false,
    canTriggerMigration: data?.model?.properties.canTriggerMigration ?? false,
    canAddResourcePlan: data?.model?.properties.canAddResourcePlan ?? false,
    isResourcePlanMigrated: data?.model?.properties.isResourcePlanMigrated ?? false,
    fetchCount,
    ...restProps,
  };
};
