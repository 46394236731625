import { useState, useEffect, ReactNode, FC, ChangeEvent, RefObject } from 'react';
import { Stack, TextField, InputAdornment, IconButton, Badge } from '@mui/material';
import { Search, Clear, FilterList } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { Button } from 'src/components/mui-components';
import { useSelectedFilterListInArray } from 'src/stores/FilterStore';
import { useSidePanelDispatch } from 'src/components/layout/SidePanel';
import { useTranslation } from 'react-i18next';
import {
  IViewOptionsField,
  ViewOptionsChangeParameters,
} from 'src/components/layout/FilterPanelLayout/types';
import { TViewOptions } from 'src/apis/savedViewAPI/types';
import { ViewOptions } from '../ViewOptions';

interface FilterSectionProps {
  changedViewOptions?: TViewOptions;
  onViewOptionsChange: (options: { name: string; value: string }[]) => void;
  viewOptionsLeftFilter?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
  toolbarAction?: ReactNode;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
  clearRowError?: () => void;
}

export const FilterSection: FC<FilterSectionProps> = ({
  changedViewOptions,
  onViewOptionsChange,
  viewOptionsLeftFilter,
  viewOptionsFields,
  viewOptionsChange,
  toolbarAction,
  activateSidePanelButtonRef,
  clearRowError,
}) => {
  const { t } = useTranslation('filter');

  // Filter sidebar
  const selectedFilterList = useSelectedFilterListInArray();
  const sidePanelDispatch = useSidePanelDispatch();

  const toggleFilterPanel = () => {
    clearRowError?.();
    sidePanelDispatch?.({ type: 'TOGGLE_PANEL' });
  };
  // Text search
  const [searchValue, setSearchValue] = useState(changedViewOptions?.['search-text'] || null);
  const [debouncedSearchValue] = useDebounce(searchValue, 250);

  useEffect(() => {
    setSearchValue(changedViewOptions?.['search-text'] || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedViewOptions?.['search-text']]);

  useEffect(() => {
    if (debouncedSearchValue !== changedViewOptions?.['search-text']) {
      onViewOptionsChange?.([{ name: 'search-text', value: debouncedSearchValue || '' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearRowError?.();
    setSearchValue(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    clearRowError?.();
    onViewOptionsChange?.([{ name: 'search-text', value: '' }]);
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
      <Stack direction="row" alignItems="center" gap={1} sx={{ mr: 'auto', width: '100%' }}>
        {viewOptionsLeftFilter && viewOptionsLeftFilter}
        <TextField
          variant="outlined"
          size="small"
          value={searchValue ?? ''}
          onChange={handleSearchChange}
          title={t('FilterFullTextSearchText')}
          placeholder={t('FilterSearchPlaceholderText')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear text"
                  onClick={handleSearchClear}
                  edge="end"
                  size="small"
                >
                  <Clear fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ ml: 'auto' }}
        />
      </Stack>

      <ViewOptions viewOptionsFields={viewOptionsFields} viewOptionsChange={viewOptionsChange} />

      <Button
        variant="text"
        startIcon={
          <Badge
            badgeContent={selectedFilterList.length}
            color="primary"
            data-automation-id="FilterContainerAddFilterCount"
          >
            <FilterList />
          </Badge>
        }
        onClick={toggleFilterPanel}
        ref={activateSidePanelButtonRef}
        data-automation-id="FilterContainerAddFilter"
      >
        {t('ShowActiveFiltersButton')}
      </Button>
      {toolbarAction && toolbarAction}
    </Stack>
  );
};
