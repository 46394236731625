import { useMutation } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';

const PUT_INTERNAL_MESSAGE_URL_PATH = '/api/v2/settings/FrontPageInternalMessage';
export const PUT_INTERNAL_MESSAGE_MSW_STATUS_KEY = 'putInternalMessageAPI';
export const PUT_INTERNAL_MESSAGE_KEY = 'PUT_INTERNAL_MESSAGE';

const updateInternalMessage = (value: string) =>
  putFetch({
    path: PUT_INTERNAL_MESSAGE_URL_PATH,
    key: PUT_INTERNAL_MESSAGE_MSW_STATUS_KEY,
    body: JSON.stringify({
      value,
    }),
  });

export const useUpdateInternalMessage = () =>
  useMutation([PUT_INTERNAL_MESSAGE_KEY], (value: string) => updateInternalMessage(value));
