import { Box, Stack, Typography } from '@mui/material';
import {
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import {
  cloneElement,
  Dispatch,
  isValidElement,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';
import table from 'src/assets/styles/variables/colors/states/table.module.scss';
import { useTranslation } from 'react-i18next';
import { IExcelConfig } from 'src/components/mui-components/DataGrid/utils/GridExcelExportMenuItem';

interface IToolbarProps {
  selectedRowProject: string[];
  setColumnsButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  filterSection: ReactNode;
  toolbarContent: ReactNode;
  // columns and excelConfig are required for excelexport
  columns?: GridColDef[];
  excelConfig?: IExcelConfig;
}

interface FilterSectionProps {
  toolbarAction?: ReactNode;
}

const toolbarActionProps = {
  button: {
    sx: {
      minWidth: 10,
      pl: 2,
    },
  },
};

export const ReportingToolbar = ({
  selectedRowProject,
  columns,
  setColumnsButtonEl,
  filterSection,
  toolbarContent,
  excelConfig,
}: IToolbarProps) => {
  const { t } = useTranslation('moveHours');

  const toolbarAction = (
    <Stack direction="row">
      <GridToolbarColumnsButton ref={setColumnsButtonEl} slotProps={toolbarActionProps} />
      {excelConfig && columns && (
        <GridToolbarExportContainer slotProps={toolbarActionProps}>
          <GridCsvExportMenuItem />
          <GridExcelExportMenuItem columns={columns} config={excelConfig} />
        </GridToolbarExportContainer>
      )}
    </Stack>
  );

  const FilterSectionToolbar = isValidElement(filterSection)
    ? cloneElement(filterSection as ReactElement<FilterSectionProps>, { toolbarAction })
    : filterSection;

  const hasSelectedRows = selectedRowProject.length > 0;

  return (
    <GridToolbarContainer sx={{ p: 0 }}>
      <Stack
        direction="row"
        justifyContent={selectedRowProject ? 'flex-start' : 'flex-end'}
        width="100%"
        flexWrap="wrap"
      >
        {hasSelectedRows ? (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            width="100%"
            sx={{ backgroundColor: table.colorActionBar, p: 1, flexWrap: 'wrap' }}
          >
            <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {selectedRowProject.length} {t('SelectedText')}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} sx={{ flexWrap: 'wrap' }}>
              {toolbarContent}
            </Stack>
          </Stack>
        ) : (
          <Box sx={{ py: 1 }} width="100%">
            {FilterSectionToolbar}
          </Box>
        )}
      </Stack>
    </GridToolbarContainer>
  );
};
