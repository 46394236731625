import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/mui-components';

interface SwitchSectionProps {
  title: string;
  items: {
    name: string;
    label: string;
    configs?: JSX.Element;
    tooltipTitle?: string;
    tooltipDesc?: string | JSX.Element;
    isVisible?: boolean;
  }[];
  checkedItems: Record<string, boolean>;
  onToggle: (name: string, isChecked: boolean, label: string) => void;
  translation: string;
}

export const SwitchSection: FC<SwitchSectionProps> = ({
  title,
  items,
  checkedItems,
  onToggle,
  translation,
}) => {
  const [openItem, setOpenItem] = useState<string | null>(null);
  const { t } = useTranslation(translation);

  return (
    <FormControl component="fieldset" data-automation-id={`SwitchSection${title}`}>
      <FormLabel component="legend" focused={false}>
        <strong>{title}</strong>
      </FormLabel>
      <FormGroup>
        {items.map((item) => {
          if (!item.isVisible) return null;
          return (
            <Box key={item.name}>
              <FormControlLabel
                key={item.name}
                control={
                  <Switch
                    checked={!!checkedItems[item.name]}
                    // #67391: Manually disabled WidgetTimeTrackingDelay until we allow user to feature toggle on FrontPage
                    // Now allow user to change company target days, but cannot feature toggle
                    disabled={item.name === 'WidgetTimeTrackingDelay'}
                    onChange={(event) => onToggle(item.name, event.target.checked, item.label)}
                    data-automation-id={`Switch${item.name}`}
                  />
                }
                label={
                  <>
                    {item.label}
                    {item.tooltipTitle && (
                      <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                        <IconButton
                          title={t('InfoIconTooltipTitle')}
                          data-automation-id={`${item.name}ModalTriggerButton`}
                          onClick={(event) => {
                            event.preventDefault();
                            setOpenItem(item.name);
                          }}
                        >
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </>
                }
              />
              {item.configs && <Box sx={{ ml: 6 }}>{item.configs}</Box>}
              <Dialog open={openItem === item.name} onClose={() => setOpenItem(null)}>
                <DialogTitle id={`InfoDialog-Title-${title}`}>{item.tooltipTitle}</DialogTitle>
                <DialogContent>
                  <DialogContentText id={`InfoDialog-Description-${title}`}>
                    {item.tooltipDesc}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenItem(null)}
                    data-automation-id={`${title}InfoModalCloseButton`}
                  >
                    <Typography>{t('ModalCloseButton')}</Typography>
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
