import { useMutation } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';

interface IUpdateSettingParams {
  identifier: string;
  value: number;
}

const PUT_SETTING_URL_PATH = '/api/v2/settings';
export const PUT_SETTING_MSW_STATUS_KEY = 'putSettingAPI';
export const PUT_SETTING_KEY = 'PUT_SETTING';

const updateSettingValue = ({ identifier, value }: IUpdateSettingParams) =>
  putFetch({
    path: `${PUT_SETTING_URL_PATH}/${identifier}`,
    key: PUT_SETTING_MSW_STATUS_KEY,
    body: JSON.stringify({
      value,
    }),
  });

export const usePutSetting = () => useMutation([PUT_SETTING_KEY], updateSettingValue);
