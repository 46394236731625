import {
  useGetMigrationStatus,
  useGetRpEmployeeCount,
  useGetRpPlanCount,
  useGetRpProjectCount,
  useGetTlpEmployeeCount,
  useGetTlpPlanCount,
  useGetTlpProjectCount,
} from 'src/apis/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';

export const useInitialSetup = () => {
  const migrationStatus = useGetMigrationStatus(true);
  const {
    canAddResourcePlan,
    isProjectMigrated,
    isResourceMigrated,
    isResourcePlanMigrated,
    fetchCount,
  } = migrationStatus;

  const employeeCondition = !isResourceMigrated;
  // Total employee count
  const { count: tlpEmployeeCount } = useGetTlpEmployeeCount(employeeCondition);
  // Migrated employee count
  const { count: rpEmployeeCount } = useGetRpEmployeeCount(employeeCondition);

  if (tlpEmployeeCount !== 0 && rpEmployeeCount === 0 && fetchCount === 3) {
    getFetch({
      path: '/api/v2/resource-planner/start-onboarding-flow',
      key: 'RESTART_MIGRATION_STATUS',
    });
  }

  const projectCondition = isResourceMigrated && !isProjectMigrated;
  // Total project count
  const { count: tlpProjectCount } = useGetTlpProjectCount(projectCondition);
  // Migrated project count
  const { count: rpProjectCount } = useGetRpProjectCount(projectCondition);

  const planCondition =
    isResourceMigrated && isProjectMigrated && canAddResourcePlan && !isResourcePlanMigrated;
  // Total resource plan count
  const { count: tlpPlanCount } = useGetTlpPlanCount(planCondition);
  // Migrated resource plan count
  const { count: rpPlanCount } = useGetRpPlanCount(planCondition);

  return {
    ...migrationStatus,
    employee: `${rpEmployeeCount}/${tlpEmployeeCount}`,
    project: `${rpProjectCount}/${tlpProjectCount}`,
    resourcePlan: `${rpPlanCount}/${tlpPlanCount}`,
  };
};
