import { useState } from 'react';
import { Button } from 'src/components/mui-components';
import { Stack } from 'src/components/ui-components/Stack';
import { Text } from 'src/components/ui-components/Text';
import { TextLink } from 'src/components/ui-components/Link';
import { useTranslation } from 'react-i18next';
import { useStopTimeTracker } from 'src/apis/timeTrackerAPI/stop';
import { ITimeTrackerProperties } from 'src/apis/types/timeTrackerAPI';
import { TimeTrackerPanelProps } from '../../types';
import styles from './TimeTrackerPanel.module.scss';

const timeRegistrationUrl = '/Registration/TimeTracking?fromTracker=1';

const displayInformation = (timeTrackerData: ITimeTrackerProperties) => {
  const { task, absenceCode } = timeTrackerData;
  if (task) {
    const {
      project: {
        name: projectName,
        customer: { name: customerName },
      },
      name: taskName,
    } = task;
    return {
      heading: customerName,
      title: projectName,
      subtitle: taskName,
    };
  }
  if (absenceCode) {
    const { name, description } = absenceCode;
    return {
      heading: 'Absence',
      title: name,
      subtitle: description,
    };
  }
  return {
    heading: '',
    title: '',
    subtitle: '',
  };
};

export const displayInformationForTest = displayInformation;

const ActiveTimeTrackerPanel = ({
  timeTrackerData,
  handleChangeActive,
  stopPath,
}: Omit<Required<TimeTrackerPanelProps>, 'isActive' | 'isTrackingTime'>) => {
  const { t } = useTranslation('header');
  const [isStopButtonDisable, setIsStopButtonDisable] = useState(false);
  const { mutate: handleChangeTrackTime } = useStopTimeTracker(setIsStopButtonDisable, stopPath);

  const { heading, title, subtitle } = displayInformation(timeTrackerData);
  return (
    <Stack verticalMargin="small">
      <Stack verticalMargin="xxSmall">
        <Text bold data-automation-id="TimeTrackerHeading">
          {heading}
        </Text>
        <Text data-automation-id="TimeTrackerTitle">{title}</Text>
        <Text italic dimmed ellipsis data-automation-id="TimeTrackerSubtitle">
          {subtitle}
        </Text>
      </Stack>
      <Button
        onClick={() => handleChangeTrackTime()}
        variant="contained"
        size="small"
        data-automation-id="TimeTrackerStopButton"
        disabled={isStopButtonDisable}
      >
        {t('TimeTrackerStopButton')}
      </Button>
      <Text>
        <TextLink
          href={timeRegistrationUrl}
          className={styles.TimeTrackerButton__linkText}
          onClick={handleChangeActive}
          data-automation-id="TimeTrackerSheetLink"
        >
          {t('TimeTrackerIsActiveTimesheetLink')}
        </TextLink>
      </Text>
    </Stack>
  );
};

const InActiveTimeTrackerPanel = ({
  handleChangeActive,
}: Pick<TimeTrackerPanelProps, 'handleChangeActive'>) => {
  const { t } = useTranslation('header');
  return (
    <Stack verticalMargin="small">
      <Text italic dimmed data-automation-id="TimeTrackerNotTrackingText">
        {t('TimeTrackerIsNotActiveTimesheetMessage')}
      </Text>
      <Text>
        <TextLink
          href={timeRegistrationUrl}
          className={styles.TimeTrackerButton__linkText}
          onClick={handleChangeActive}
          data-automation-id="TimeTrackerSheetLink"
        >
          {t('TimeTrackerIsNotActiveTimesheetLink')}
        </TextLink>
      </Text>
    </Stack>
  );
};

export const TimeTrackerPanel = ({
  isActive,
  handleChangeActive,
  timeTrackerData,
  stopPath,
}: TimeTrackerPanelProps) =>
  isActive ? (
    <div
      id="time-tracker"
      className={styles.TimeTrackerButtonContent}
      data-automation-id="TimeTrackerContent"
    >
      <Stack verticalMargin="small">
        {timeTrackerData ? (
          <ActiveTimeTrackerPanel
            stopPath={stopPath}
            timeTrackerData={timeTrackerData}
            handleChangeActive={handleChangeActive}
          />
        ) : (
          <InActiveTimeTrackerPanel handleChangeActive={handleChangeActive} />
        )}
      </Stack>
    </div>
  ) : null;
